import {
  TrackShipmentActions,
  SetInputValidated,
  SET_INPUT_TRACK_SHIPMENT,
  SET_FORM_LOADING_TRACK_SHIPMENT,
  SetFormLoading,
  SET_FORM_SUCCESS_TRACK_SHIPMENT,
  SetFormSuccess,
  SET_GENERAL_ERROR_TRACK_SHIPMENT,
  SetGeneralErrorMessage,
  RESET_FORM_TRACK_SHIPMENT,
} from './actions';

export type TrackShipmentData = {
  isLoading: boolean;
  isSuccess: boolean;
  generalError: {
    message?: string;
  };
  numbers: {
    value: string;
    error: string;
  };
}

export const initialState: TrackShipmentData = {
  isLoading: false,
  isSuccess: false,
  generalError: {
    message: '',
  },
  numbers: {
    value: '',
    error: '',
  },
};

const TrackShipment = (
  state: TrackShipmentData = initialState,
  action: TrackShipmentActions,
): TrackShipmentData => {
  switch (action.type) {
    case SET_INPUT_TRACK_SHIPMENT:
      return {
        ...state,
        ...(action as SetInputValidated).payload,
      };
    case SET_FORM_LOADING_TRACK_SHIPMENT: {
      return {
        ...state,
        isLoading: (action as SetFormLoading).payload.isLoading,
      };
    }
    case SET_FORM_SUCCESS_TRACK_SHIPMENT: {
      return {
        ...state,
        isSuccess: (action as SetFormSuccess).payload.isSuccess,
      };
    }
    case SET_GENERAL_ERROR_TRACK_SHIPMENT: {
      return {
        ...state,
        generalError: {
          message: (action as SetGeneralErrorMessage).payload,
        },
      };
    }
    case RESET_FORM_TRACK_SHIPMENT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default TrackShipment;
